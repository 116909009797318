import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import adminRoutes from "@/router/admin";
import {auth} from "@/service/Auth";

let routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/rubicon',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/categorie/:category',
        name: 'nomine-list',
        component: () => import('@/views/CategoryView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const host: any = window.location;
const regex = new RegExp(/r[\w\s]*b[\w\s]*c[\w\s]*n$/i);


routes = routes.concat(adminRoutes)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
