import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

let adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/editor',
        name: 'admin-category-list',
        component: () => import('@/views/app/CategoryView.vue'),
        meta: {
            title: 'Categories',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/editor/categorie/:category',
        name: 'admin-nomine-list',
        component: () => import('@/views/app/NomineView.vue'),
        meta: {
            title: 'Nominés',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

export default adminRoutes
