import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {auth} from "@/service/Auth";
import {helper} from "@/service/Helper";



router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title as any ?? 'Konnect'
    }
    if (to.meta && to.meta.requiredRoles) {
        const requiredRoles: any = to.meta.requiredRoles
        if (auth.hasOneRole(requiredRoles)) {
            next();
            return;
        } else {
            console.log(from)
            console.log(auth.getRoles())
            console.log('has no role')
            console.log(to.meta.requiredRoles)
            let text = "Vous devez disposez de l'un des accès suivant pour accéder à cette page :";
            requiredRoles.forEach((r: any) => {
                text += '\n' + r
            })
            auth.logout()
        }
    }
    next();
});


const app = createApp(App).use(store).use(router)


app.config.globalProperties.hasRole = (role: string) => {
    return auth.hasRole(role);
};
app.config.globalProperties.hasRoles = (roles: []) => {
    return auth.hasRoles(roles);
};
app.config.globalProperties.empty = (value: []) => {
    return helper.empty(value);
};

app.config.globalProperties.trans = (text: string) => {
    return text;
};

app.mount('#app')
